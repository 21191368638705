
import { defineComponent, onMounted, ref, computed } from "vue";
import ApiService from "@/core/services/ApiService";
//import  Master from "@/models/MasterModel"
import * as Mast from "@/models/MasterModel"
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "slaves-table",
  components: {},
  props: {
    widgetClasses: String,
   
  },
  setup(props) {
    
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
   
    const masterId = computed(() => store.getters.curMasterId)
       
    const slaves = ref<Mast.Slave[]>([]as Mast.Slave[]);
    onMounted(() => {
      getSlaves()

    })

    const getSlaves = () =>{
        const postData ={//todo geert  tijdelijke params
        "searchString": '',
        "parentIds": [masterId.value],
        "pageSize": 10000,
        "pageNumber": ''
         }as any;
        ApiService.post("/device/slaves",postData)
        .then(({ data }) => {
            console.log('getSlaves response data:');//todo geert
            console.log({...data});//todo geert
            slaves.value = data.results; 
        })
        .catch(({ response }) => {
          console.log("Fout getSlaves")
           
        });
    }

    const clickNewSlave =() => {
          console.log("goNewSlave");
          store.commit('setSlaveId',0) 
          router.push({ name: "editslave" });

    }

    const clickEditSlave =(data) => {
      console.log("goEditSlave");
       console.log(data)
       store.commit('setSlaveId',data.id) 
          router.push({ name: "editslave"});
    }

    const clickDeleteSlave =(data:Mast.Slave) => {
      Swal.fire({

          title: 'Are you sure to delete slave '+data.displayName +' ?',
          showCancelButton: true,
          icon:"warning",  
          buttonsStyling: true,
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          
        }).then((result) => { 
          if(result.isConfirmed){
              deleteSlave(data)
          } 
        });
    }

    const deleteSlave =(data) => {
      console.log("deleteSlave:");
      console.log(data)
   
        ApiService.post("/device/slavedelete",data as any)
        .then(({ data }) => {
            console.log('delete slave response data:');//todo geert
            console.log({...data});//todo geert
            getSlaves();
        })
        .catch(({ response }) => {
          console.log("Fout slavedelete")
           
        });

    }


    return {
      slaves,
      clickNewSlave,
      clickEditSlave,
      clickDeleteSlave,
      t
    };
  },
});
