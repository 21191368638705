
import { defineComponent, onMounted } from "vue";
//import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
import mastereditform from "@/components/MasterEditForm.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
//import { MenuComponent } from "@/assets/ts/components";
//import masterstable from "@/components/MastersTable.vue"

export default defineComponent({
  name: "master-edit-page",
  components: {
    mastereditform,
  },
  setup() {
    onMounted(() => {
      //setCurrentPageBreadcrumbs("Charts", ["Widgets"]);
      setCurrentPageTitle("Edit Master");
      //MenuComponent.reinitialization();
    });
  },
});
