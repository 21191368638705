
import { defineComponent, ref, onMounted, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import Master from "@/models/MasterModel";
import * as Mast from "@/models/MasterModel";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import slavesTable from "@/components/SlavesTable.vue";
import BnPHelpers from "@/assets/ts/_utils/BnPHelpers";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "master-edit-form",
  components: {
    ErrorMessage,
    Field,
    Form,
    slavesTable,
  },
  
  setup() {
    const router = useRouter();
    const { t} = useI18n();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);
    const networks = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);
    const baudRates = ref<Mast.GenericOption[]>([{} as Mast.GenericOption]);
    var initKlaar = false;
    const store = useStore();
    const masterId = computed(() => store.getters.curMasterId);

    var NewMasterData = ref<Master>({
      id: 0,
      device_id: 0,
      name: "",
      serial: "",
      ping: 0,
      send: 0,
      baud: undefined,
      // stopBits: 0,
      // bits: 0,
      // parity: "",
      delay: 0,
      publish: "",
      subscribe: "",
      slaves: [],
      status: "",
      statusTime: "",
      statusTimeRelative: "",
      networkId: 0,
      network: {
        id: 0,
        genericOptionTypeId: 0,
        int: 0,
        float: 0,
        string: "",
        selected: false,
      },
      pcbSerial: "",
    });

    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("Name"),
      serial: Yup.string().required().label("serial"),
      ping: Yup.number().required().label("ping"),
      send: Yup.number().required().label("send"),
      baud: Yup.number(),
      network: Yup.number().required().label("network"),
      // stopBits: Yup.string().required().label("stopBits"),
      // bits: Yup.number().required().label("bits"),
      // parity: Yup.string().required().label("parity"),
      delay: Yup.number().required().label("delay"),
      //publish: Yup.string().required().label("publish"),
      //pcbSerial: Yup.string().label("pcbSerial"),
    });

    const test = () => {
      console.log("classAdd");
      document.body.classList.add("overlay-block");
      //document.body.classList.add("page-loading-enabled");
      //document.body.classList.add("page-loading");
    };

    const getMasterById = (id) => {
      console.log("getMasterById id:");
      console.log(id);

      ApiService.get2("/device/masterget?id=" + id)
        .then(({ data }) => {
          console.log("getmaster response data:"); //todo geert
          console.log({ ...data }); //todo geert
          NewMasterData.value = data as Master;
        })
        .catch(({ response }) => {
          console.log("Fout getmasterById");
        });
    };

    const submit = () => {
      console.log("NewMasterdata");
      console.log(NewMasterData.value);
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button and busy indicator
      submitButtonRef.value.disabled = true;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.post("/device/masterupdate", NewMasterData.value as any)
        .then(({ data }) => {
          console.log("submit master response data:"); //todo geert
          console.log({ ...data }); //todo geert
          router.push({ name: "administration" });
        })
        .catch(({ response }) => {
          console.log("Fout masterUpdate");
          Swal.fire({
            text: ApiService.getModelStateError(response.data.errors), //todo algemene error message
            icon: "error",
            buttonsStyling: true,
            confirmButtonText: "Ok",
          });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const clickCancel = () => {
      router.go(-1);
    };

    onMounted(() => {
      BnPHelpers.getOptions("/template/networks", networks)
        .then(() => {
          BnPHelpers.getOptions("/template/baudRates", baudRates);
        })
        .then(() => {
          if (masterId.value != 0) {
            getMasterById(masterId.value);
            console.log("NewmasterData is nu:");
            console.log(NewMasterData.value.name);
          }
        });
    });

    return {
      test,
      NewMasterData,
      validationSchema,
      submit,
      submitButtonRef,
      newAddressModalRef,
      clickCancel,
      networks,
      baudRates,
      t,
      initKlaar,
    };
  },
});
